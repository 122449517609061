import { Foot, FootItems, FootItem, FootHeader, FootBody } from "../styled/Footer";

function Footer({color}) {
  return (
    <Foot>
      <FootItems >
        <FootItem col="1" color={color}>
          <FootHeader>contact</FootHeader>
          <FootBody>
          <a href="mailto:info@voidchicago.com">info@voidchicago.com</a>
          </FootBody>
          {/* <FootHeader>media inquiries</FootHeader>
          <FootBody>
          <a href="mailto:media@voidchi.com">media@voidchi.com</a>
          </FootBody> */}
          </FootItem>
        <FootItem col="2" color={color}>
          <FootHeader>no reservations</FootHeader>
          <FootBody>
          <p>come as you are</p>
          </FootBody>
        </FootItem>
        <FootItem col="3" color={color}>
          <FootHeader>hours</FootHeader>
          <FootBody>
          <p>Tuesday - Saturday</p>
          <p>Dinner: 5:00 - 10:00</p>
          <p>Bar: 5:00 - midnight</p>
          <p>Closed Sunday</p>
          <p>Closed Monday</p>
          </FootBody>
        </FootItem>
        <FootItem col="4" end color={color}>
          <FootHeader>address</FootHeader>
          <FootBody>
          <a href="https://www.google.com/maps/dir//2937+N+Milwaukee+Ave+Chicago+IL+60618" target="_blank">2937 N Milwaukee Ave.
          </a>
          <a href="https://www.google.com/maps/dir//2937+N+Milwaukee+Ave+Chicago+IL+60618">Chicago, IL</a>
          </FootBody>


          {/* /////////////// */}
                {/* map? */}
          {/* /////////////// */}

        </FootItem>
      </FootItems>
    </Foot>
  );
}

export default Footer;
