import styled, { css } from "styled-components";
import { mediaQueries } from "./mediaQueries.js";

export const MenuHeader = styled.div`
  font-size: 2.5em;
  color: whitesmoke;
  font-family: "Ilisarniq-Black";
  /* border: 1px solid hotpink; */

  ${mediaQueries("monitor")} {
    /* padding-top: 1em; */
  }
`;

export const MenuSubHeader = styled(MenuHeader)`
  font-size: 1.5em;
  padding-top: 0.5em;

  ${mediaQueries("monitor")} {
    padding-top: 1.8em;
  }
`;

export const MenuItems = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  ${mediaQueries("monitor")} {
    max-height: 85vh;
    max-width: 90vw;
  }
`;

export const MenuItemContainer = styled.li`
  padding: 0.2em;
  margin: .2em .2em  .2em;
  /* border: 1px solid hotpink;
border-radius: 5px; */
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "itemName itemName itemName . price"
    "ingredients ingredients ingredients ingredients ingredients"
    "description description description description description";
  border: 2px solid transparent;
  border-radius: 15px;
  border-image: linear-gradient(
    to left bottom,
    rgb(58, 183, 231) 0%,
    rgb(217, 120, 233) 100%
  );
  border-image-slice: 1;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3); */
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  ${mediaQueries("monitor")} {
    max-width: 400px;
  }
`;

export const MenuItemComponent = styled.div`
  color: whitesmoke;
  /* border: 1px solid hotpink; */
`;

export const Name = styled(MenuItemComponent)`
  grid-area: itemName;
  font-family: "Ilisarniq-Regular";
  font-size: 1.2em;
`;

export const Price = styled(MenuItemComponent)`
  grid-area: price;
`;

export const Ingredients = styled(MenuItemComponent)`
  grid-area: ingredients;
  font-family: "Ilisarniq-Italic";
  border-top: 1px dashed grey;
  margin: 0.2em 0;
  padding: 0.2em 0;
`;

export const Description = styled(MenuItemComponent)`
  grid-area: description;
  font-family: "Ilisarniq-Demi";
`;
